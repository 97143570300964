<template>
  <div class="images-container">
    <ImageSearch @search="imageSearch"/>
    <div class="title">
      <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAABGCAMAAABG8BK2AAAAG1BMVEVHcExE371B38RA3MRD4MFB38RC38Q84NFH37gb0im1AAAABnRSTlMA33kgSLC4uDBuAAABsklEQVR42u3W25KCQAwEUDOX4P9/sZABu6A3G8O8Wcb3U53YUDx+8yXTukgvs4iojZRphKAs8tTtZ7PoAiiHQNklKXnkpCyWZ6FEEWIKoEERFCA2QKAQFCG6K08gagqgEKE4OaiDQBw4Y6kdqq5SgJizITAuUHcZbMQ7HSthPmN0ONcbQ6rxaTgMVuKlOA6HQZYBcRieItcTO7cpprhTB+TXRoFE0GkpCqNRkorVOMyAgNTqlk/KKRFqTEgR579qdg8pSHTcGIGAjDs7pWEIxhmxEaczBCENIdu4DEOMRIwDMRIzDLX2AJJlACHW4rwropfNCgGBETEkqVSro+CZSjI6nG6dUtVbafTYS+saRnUmjTltMCByzK4YsyH5NEBOaXK3wSCNOTd7YwqWunkbOvHd29BSd1uMNNnewPjrxPO3AZTvzUiDnabTmDPbm13Q+TSf3ab/2xuUL2AaZ3n3xglTgg9IflEgjEbfW7Xza8tP0xsBgLg4uI0SEkPq9QZICHEalBhIBro8DEpIAKE3WApJEtDoTd0arnuSmkIMKpaoWKUsDZIkE4mU95fAmuQ3XzAvkJA6BgUUrYwAAAAASUVORK5CYII=">
      <p>热门模型</p>
    </div>
    <ImageContainer :images="images"/>
  </div>
</template>

<script>
import {imageBase, searchImageBase} from "@/api/image";

export default ({
  components: {
    ImageContainer: () => import('@/views/application/drawer/component/ImageContainer.vue'),
    ImageSearch: () => import('@/views/application/drawer/component/ImageSearch.vue')
  },
  data() {
    return {
      images: []
    }
  },

  methods: {
    initImages() {
      imageBase().then(res => {
        this.images = res.data
      })
    },
    imageSearch(text) {
      searchImageBase(text).then(res => {
        this.images = res.data
      })
    }

  },
  mounted() {
    this.initImages()
  }

})
</script>

<style scoped lang="scss">
.images-container {
  padding: 0 15px;

  .title {
    display: flex;
    align-items: center;
    margin: 15px 0;

    img {
      width: 30px;
      height: 30px;
    }

    p {
      font-size: 20px;
      font-weight: 600;
      margin-left: 10px;
      margin-bottom: 0;
    }
  }
}
</style>