import request from "@/utils/request";
import {APICONFIG} from "@/utils/apiConfig";


/**
 * 查询image基础数据
 */
export function imageBase() {
    return request({
        url: `${APICONFIG}/image/base`,
        method: 'get',
    })
}

/**
 * 查询image基础数据
 */
export function searchImageBase(text) {
    return request({
        url: `${APICONFIG}/image/base?text=${text}`,
        method: 'get',
    })
}